.images
  display: flex
  width: 100%
  overflow: scroll
  padding: 10px 0
  gap: 1px

.images--image
  overflow: hidden
  width: 200px
  aspect-ratio: 1
  height: 200px
  min-width: 100px
  position: relative

.images--image-content
  position: absolute
  bottom: 10px
  left: 10px
  right: 10px
  background-color: #fffa
  border-radius: 5px
  padding: 5px
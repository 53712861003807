.promo-codes
  display: grid
  grid-template-columns: repeat(7, minmax(10px, auto))
  margin: 20px 0

.promo-codes--header
  display: contents

  & div
    font-weight: bold
    margin-bottom: 10px

.promo-codes--promo-code
  display: contents

  &.-deleted div
    color: red

.promo-codes--url
  grid-column: 1/8
  padding-bottom: 20px

.content
  display: flex
  flex-direction: column
  height: 100%

  @media print
    height: unset

.content--container
  position: relative
  background-color: #fffc
  border-radius: 10px
  padding: 20px 10px
  flex-grow: 1

  display: flex
  flex-direction: column
  justify-content: space-between

  overflow: scroll
  @media print
    overflow: unset

  margin: 0 10px 10px 10px

  @media screen and (min-width: 750px) and (min-height: 750px)
    margin: 0 50px 10px 50px
    padding: 50px
    border-radius: 50px

  @media screen and (min-height: 750px)
    margin-bottom: 50px
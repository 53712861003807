.trip
  display: flex
  margin-bottom: 20px
  margin-top: 10px
  gap: 20px

.trip--name
  font-size: 1.5em

.trip--stays
  margin-top: 40px

.trip--stay
  margin-top: 20px
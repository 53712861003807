.bug-reports
  display: grid
  grid-template-columns: repeat(10, minmax(10px, auto))
  margin: 20px 0
  gap: 10px

.bug-reports--header
  display: contents

  & div
    font-weight: bold
    margin-bottom: 10px

.bug-reports--bug-report
  display: contents

.bug-reports--state
  border-radius: 5px
  padding: 2px
  text-align: center
  margin-bottom: auto
  
  &.-new
    background-color: yellow
    color: black
  &.-approved
    background-color: red
    color: white
  &.-declined
    background-color: gray
    color: white
  &.-fixed
    background-color: green
    color: white

.purchases
  display: grid
  grid-template-columns: repeat(8, minmax(10px, auto))
  gap: 10px

.purchases--header
  display: contents

  & div
    font-weight: bold
    margin-bottom: 10px

.purchases--purchase
  display: contents

.purchases--user
  grid-column-start: 1
  grid-column-end: 9
  font-weight: bold
  margin-top: 40px
  margin-bottom: 10px

.purchases--state
  border-radius: 5px
  padding: 2px
  text-align: center
  margin-bottom: auto

  &.-cancelled, &.-invalid
    background-color: red
    color: white
  &.-pending
    background-color: yellow
    color: black
  &.-purchased
    background-color: green
    color: white

.form
  display: flex
  flex-direction: column
  gap: 10px
  margin-top: 50px
  margin-bottom: 50px

  & label.error
    color: red

.form--inputs
  display: grid
  grid-template-columns: auto 1fr
  gap: 10px

.form--group
  display: contents

.form--input-group
  display: flex
  gap: 10px

.form--submit
  margin-top: 10px
.session
  display: flex
  justify-content: space-between
  align-items: center
  margin: 0 10px 0 10px
  // min-height: 50px
  z-index: 1

  @media screen and (min-width: 750px)
    margin: 0 50px

.session--sign-in
  height: 50px
  display: flex
  align-items: center
  // margin-top: 10px
  // margin-bottom: 10px

.session--app
  display: flex
  font-size: 2em
  align-items: center
  text-decoration: none
  color: #000
  gap: 10px
  margin-top: 10px
  margin-bottom: 10px

.session--app-icon
  border-radius: 25px
  overflow: hidden
  height: 50px
  width: 50px
  margin-top: 10px
  margin-bottom: 10px

.session--content
  height: 50px
  isolation: isolate
  position: relative
  width: 50px
  display: flex
  justify-content: flex-end
  margin-top: 10px
  margin-bottom: 10px

  background-color: #fffc
  overflow: hidden
  border-radius: 50px
  // border: 1px solid #000

  transition: width 0.25s

  &:hover
    width: 200px

.session--image
  width: 100%
  height: 100%

.session--menu
  width: 130px
  height: 100%
  position: absolute
  left: 0
  display: flex
  flex-direction: column
  align-items: flex-end
  justify-content: center
  padding: 0 10px
  gap: 5px

.session--user
  width: 50px
  height: 50px
  overflow: hidden
  border-radius: 50px
  background-color: #000
  display: flex
  justify-content: center
  align-items: center
  //margin: -1px
  z-index: 1

body
  margin: 0
  font-family: Arial, Helvetica, sans-serif
  overflow: hidden
  height: 100dvh
  width: 100dvw

  @media print
    overflow: unset

  background-image: url("https://tripper-app.s3.eu-central-1.amazonaws.com/bus_large_c.jpg")
  background-repeat: no-repeat
  background-attachment: fixed
  background-position: center
  background-size: cover

a
  color: #0B2E52

h1
  font-weight: normal
  margin: 0

h3
  page-break-after: avoid

.container
  display: flex
  flex-direction: column
  align-items: center

.container div
  text-align: center

.image
  border-color: #3873AE
  border-style: solid
  border-width: 1px
  border-radius: 15px
  overflow: hidden
  width: 200px
  height: 200px
  position: relative
  box-shadow: 0px 5px 10px #3873AE

  &.-small
    width: 100px
    height: 100px

img
  object-fit: cover
  width: 100%
  height: 100%

.packing-list
  column-count: 2

.packing-list-category
  margin-bottom: 20px
  page-break-inside: avoid

.packing-list-category-name
  margin-bottom: 10px
  font-size: 1.2rem

.checkbox-container
  display: flex
  flex-direction: row
  position: relative
  gap: 10px
  align-items: center
  // cursor: pointer
  -webkit-user-select: none
  -moz-user-select: none
  -ms-user-select: none
  user-select: none
  margin-bottom: 5px

.checkbox-container input 
  position: absolute
  opacity: 0
  // cursor: pointer
  height: 0
  width: 0

.checkbox
  height: 15px
  width: 15px
  background-color: #fff
  border-radius: 5px
  border: 2px solid #000
  display: flex
  justify-content: center
  align-items: center
  print-color-adjust: exact
  aspect-ratio: 1
  position: relative

// On mouse-over, add a grey background color
//.checkbox-container:hover input ~ .checkbox-label
//  background-color: #ccc

.checkbox-container input:checked ~ .checkbox
  background-color: #aaa
  border: 2px solid #888
  print-color-adjust: exact

.checkbox-container input:checked ~ .checkbox:after
  display: block

.checkbox-container .checkbox:after
  content: ""
  position: absolute
  display: none
  // left: 7px
  top: 1px
  width: 3px
  height: 8px
  border: solid white
  border-width: 0 3px 3px 0
  -webkit-transform: rotate(45deg)
  -ms-transform: rotate(45deg)
  transform: rotate(45deg)
  print-color-adjust: exact

.checkbox-label[checked="true"]
  color: #aaa
  print-color-adjust: exact

form
  margin: 0

button
  &.-link
    border: none
    background: none
    font-size: 1em
    color: #0B2E52
    text-decoration: underline
    padding: 0
    cursor: pointer

.flex
  display: flex
  gap: 5px

  &.-end
    justify-content: flex-end
.fa-large
  font-size: 25px

.fa-white
  color: white

.fa-red
  color: red

.fa-green
  color: green
.play
  margin: 30px

.play--qr-code
  width: 150px
  height: 150px
  border-radius: 20px
  border: 3px solid black
  padding: 10px
  
.play--badge
  width: 150px
.users
  display: grid
  grid-template-columns: repeat(7, minmax(10px, auto))
  margin: 20px 0
  gap: 10px

.users--header
  display: contents

  & div
    font-weight: bold
    margin-bottom: 10px

.users--user
  display: contents

  &[deleted="true"] a
    color: red
    text-decoration: line-through

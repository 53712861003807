.footer
  display: flex
  justify-content: space-between
  margin-top: 40px

.footer--links
  display: flex
  flex-direction: row
  flex-wrap: wrap
  column-gap: 10px

  //@media screen and (min-width: 750px)
  //  flex-direction: row

  & a
    display: block

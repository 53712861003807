.user
  display: flex
  flex-wrap: wrap
  flex-direction: row
  column-gap: 50px

  .user--trip
    display: flex
    margin-bottom: 20px
    gap: 20px
